<template>
  <div>
    <div class="__bg">
      <img src="../../assets/img/recommends/bg.png" alt="" />
    </div>
    <div class="__content" v-if="detail">
      <div class="_type_title">
        <div class="_type_title_inner">
          <div>
            <a>精选推荐</a>
            <img src="../../assets/img/recommends/arrow.png" class="arrow" />
            <a @click="goBack()">{{ parentLinkName }}</a>
            <img
              src="../../assets/img/recommends/arrow.png"
              class="arrow"
            /><span>{{ detail.title }}</span>
          </div>
        </div>
      </div>
      <div class="article_body">
        <h1>{{ detail.title }}</h1>
        <span class="_date_time">发布时间：{{ detail.create_time }}</span>
        <div class="ql-snow">
          <div class="article_content ql-editor" v-html="detail.content"></div>
        </div>
        <div class="_labels" v-if="detail.label">
          文章标签：
          <span
            v-for="(it, index) in detail.label.split(',')"
            :key="index"
            @click="goLabel(it)"
            >{{ it }}</span
          >
        </div>
      </div>
    </div>
    <div class="_loading" v-else>
      <img src="../../assets/img/recommends/loading.gif" alt="" />
    </div>
  </div>
</template>
<script>
import { getDetailById } from "../../api/wallpaper";

export default {
  name: "Acticle",
  data() {
    return {
      parentLinkName: "",
      classificationId: "",
      detail: null,
    };
  },
  computed: {},
  created() {
    this.getInfo();
  },
  methods: {
    goBack() {
      if (this.$route.query.label) {
        this.$router.push({
          path: "/acticle_type",
          query: { label: this.$route.query.label },
        });
      } else {
        this.$router.push({
          path: "/recommends",
          query: { classificationId: this.classificationId },
        });
      }
    },
    async getInfo() {
      const res = await getDetailById({ id: this.$route.query.id });
      if (this.$route.query.label) {
        this.parentLinkName = this.$route.query.label;
      } else {
        this.parentLinkName = res.data.classification_name;
        this.classificationId = res.data.classification;
      }
      this.detail = res.data;
      this.changeMeta(res.data.keyword, res.data.title);
    },
    changeMeta(str, title) {
      if (str)
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", str);
      if (title) document.title = title + "_啊噗啊噗UPUPOO官网";
    },
    goLabel(label) {
      this.$router.push({ path: "/acticle_type", query: { label } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url(../../assets/style/quill.snow.css);
.__bg {
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  height: 332px;
  z-index: 2;
  img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }
}
.__content {
  position: relative;
  z-index: 3;
  width: 100%;
  min-height: calc(100vh - 243px);
}
.__middle {
  width: 1300px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
.__wl {
  display: block;
  content: " ";
  background: #8ea9fe;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
._type_title {
  @extend .__middle;
  font-size: 16px;
  font-weight: 400;
  color: #161616;
  height: 24px;
  line-height: 24px;
  margin: 60px 0 79px;
  border-bottom: 1px solid #e9f0f6;
  ._type_title_inner {
    width: auto;
    max-width: 497px;
    position: relative;
    float: left;
    padding-right: 12px;
    div {
      position: relative;
      z-index: 2;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &::before {
      @extend .__wl;
      width: 100%;
      height: 8px;
      opacity: 0.5;
      left: 0;
    }
  }
  .arrow {
    display: inline;
    width: 29px;
    height: 11px;
    margin: 0 3px;
  }
  a {
    &:hover {
      color: #3da7fe;
    }
  }
  span {
    font-weight: bolder;
    display: inline;
    font-size: 18px;
  }
  &::after {
    @extend .__wl;
    width: 50px;
    height: 9px;
    border-radius: 2px;
    right: 0;
  }
}
.article_body {
  @extend .__middle;
  text-align: center;
  h1 {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #161616;
    line-height: 36px;
    border-bottom: solid 1px #e9f0f6;
    padding-bottom: 16px;
    margin-bottom: 22px;
  }
  ._date_time {
    height: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #161616;
    line-height: 24px;
    margin-bottom: 42px;
  }
  .article_content {
    width: 800px;
    margin: 0 auto 60px;
    /deep/ p {
      font-size: 16px;
    }
    /deep/ img {
      display: inline-block;
      margin: 21px 0;
    }
    /deep/ em {
      font-style: italic;
    }
    /deep/ .ql-video {
      height: 512px;
      width: 100%;
      margin: 32px auto;
      background-color: transparent;
    }
  }
  ._labels {
    text-align: left;
    margin-bottom: 67px;
    span {
      padding: 4px 8px;
      background: rgba(61, 167, 254, 0.1);
      border-radius: 14px;
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #3da7fe;
      margin-right: 8px;
      &:hover {
        background: #3da7fe;
        color: #fff;
      }
    }
  }
}
.ql-align-center {
  img {
    display: block;
    width: 100%;
    margin: 21px 0;
  }
}
._loading {
  img {
    margin: 0 auto;
    width: 400px;
  }
}
</style>
